
import React from 'react';
import './forms.css';

const CategoryForm = (props) => {
  return (
    <div className="formContainer">
      <div className="form-group row">
        <label className="col-form-label col-2" htmlFor="category_name_submit">Category</label>
        <input
          name="category_name"
          onChange={props.handleInputChange}
          type="text"
          className="form-control-sm col-6"
          id="category_name_submit"
          placeholder="Type New Category Here..."
        />
      </div>
      <div className="form-group row">
        <label className="col-form-label col-2" htmlFor="category_phase_submit">Phase</label>
        <select
          name="category_phase"
          onChange={props.handleInputChange}
          className="form-control-sm col-6 custom-select "
          id="category_phase_submit"
          placeholder="select phase..."
        >
          <option value="">pick a phase for this category...</option>
          {props.phases.map((phase, i) => {
            return (
              <option key={`phase-${i}`} value={phase.id}>{phase.phase_name}</option>
            );
          })}
        </select>
      </div>
      {props.alert ? <span className={`alert-text form-text ${props.alert === 'Successfully Created' ? 'text-success':'text-danger'}`}>{props.alert} <i className="dismiss-alert fas fa-times-circle" onClick={props.clearAlert}></i></span> : null }
      <button
        type="button"
        className="btn btn-primary"
        onClick={props.handleAdd}>
        Submit
      </button>
    </div>
  );
};

export default CategoryForm;
