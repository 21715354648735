
import React from 'react';

const ItemQuantity = (props) => {
  const payment_percentage = props.item.payment_percentage * 100 >= 100;
  let contribution = props.item.payment_percentage * props.item.totalRequested;
  let max = props.item.totalRequested - contribution;
  max = max > 10000 ? 10000 : max;
  let tipMsg = payment_percentage ? 'Input disabled donations met or exceeded' : `Value of your donation can be up to ${max}`;
  return (
    <div className={props.col}>
      <span className="title" data-effect="solid" data-delayshow='100' data-tip={tipMsg}>Donation Value <i className="far fa-question-circle"></i></span>
      <div className="input-group input-group-small quantity">
        <span className="currency">
          $
          <input
            className={props.invalidValue ? 'redFlag' : ''}
            type="number"
            name="amount"
            disabled={payment_percentage}
            min="1"
            max={max.toString()}
            id={`amount_picker${props.item.id}`}
            onChange={(e) => props.qChange(e,props.item)}
          />
        </span>
      </div>
    </div>
  );
};

export default ItemQuantity;
