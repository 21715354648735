import axios from 'axios';


export default {
  getAll: function (active = null, sort = null, filter = '') {
    return axios.get(`api/all/wishlist/phases/?active=${active}&sort=${sort}&filter=${filter}`);
  },
  getItemAmounts: function() {
    return axios.get('api/all/item-contributor-amounts');
  },
  addPhase: function (data) {
    return axios.post('api/modify/addPhase', data);
  },
  updatePhase: function(data, id) {
    return axios.put(`api/modify/phases/${id}`, data);
  },
  addCategory: function (catName) {
    return axios.post('api/modify/addcategory', catName);
  },
  addItem: function (itemObj) {
    return axios.post('api/modify/additem', itemObj);
  },
  deleteItem: function (id) {
    return axios.put(`api/modify/deleteitem/${id}`);
  },
  deleteCategory: function (id) {
    return axios.put(`api/modify/deletecategory/${id}`);
  },
  updateRecord: function (data) {
    return axios.put('api/modify/update/', data);
  },
  updateItemField: function (id, value, field) {
    const data = {
      origin: true,
      item: {},
    };
    data.item[field] = value;
    return axios.put(`api/modify/updateitemfield/${id}`, data);
  },
  updateCategory: function (cat_id, item_id) {
    return axios.put(`api/modify/updateCategory/${cat_id}/${item_id}`);
  },
  updateImage: function (id, data) {
    let form = new FormData();
    for (let key in data) {
      form.append(key, data[key]);
    }
    return axios.put(`api/modify/updateItemImage/${id}`, form);
  },
};
