import React, { Component } from 'react';
import './Wishlist.css';
import { default as API } from '../../util/API';
import { default as pp } from '../../util/Paypal';
import { default as Category } from '../../components/Category';
import { default as Checkout } from '../../components/Checkout/Checkout';
import Basket from '../../components/Basket/Basket';
import { Header } from '../../components/Header';
import { default as WishListNav } from '../../components/WishListNav';
import { InnerNav } from '../../components/InnerNav';
import ItemFilter from '../../components/ItemFilter';
import {NoData} from '../../components/NoData';
import { Loader } from '../../components/Loader';

export default class Wishlist extends Component {
  constructor(props) {
    super(props);
    this.removeFromBasket = this.removeFromBasket.bind(this);
    this.addToBasket = this.addToBasket.bind(this);
    this.checkOut = this.checkOut.bind(this);
    this.returnFromCheckOut = this.returnFromCheckOut.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.getBasketTotal = this.getBasketTotal.bind(this);
    this.getCategoryView = this.getCategoryView.bind(this);
    this.hoverEffect = this.hoverEffect.bind(this);
    this.state = {
      data: [],
      basket: [],
      message: null,
      success: false,
      loading: true,
      item: '',
      filter: 'all',
      removePaypalButton: false,
      current_phase: null,
      categories: [],
      total: 0,
      checkout: false,
      disableAddToBasket: {}
    };
  }

  getFilter() {
    let itemFilter = localStorage.getItem('itemFilter');
    let filter;
    switch(itemFilter) {
    case 'all':
      filter = '';
      break;
    case 'balance':
      filter = [['items.payment_percentage.lt', '1']];
      break;
    case 'paid':
      filter = [['items.payment_percentage.gte', '1']];
      break;
    default:
      filter = '';
      break;
    }

    return filter;
  }

  componentDidMount() {
    let { search } = this.props.location;
    let id = search.match('tab=') ? search.split('=')[1] : null;
    let current_phase;
    this.hoverEffect();
    let itemFilter = localStorage.getItem('itemFilter');
    console.log(!this.state.data.length);
    if (!this.state.data.length) {
      API.getAll(true, null, this.getFilter())
        .then((res) => {
          if (id) {
            let phase = res.data.find((obj) => Number(obj.id) === Number(id));
            current_phase = phase ? phase : res.data[0];
          } else {
            current_phase = res.data[0];
          }
          setTimeout(() => {
            this.setState({
              filter: itemFilter ? itemFilter : 'all',
              data: res.data,
              current_phase: current_phase,
              categories: current_phase.Categories,
              loading: false
            });
          }, 1000);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  checkOut() {
    this.setState({ checkout: true });
  }

  returnFromCheckOut() {
    this.setState({ checkout: false });
  }


  handleInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  }

  addToBasket(Obj) {
    let temp = this.state.basket;
    let disableAddToBasket = this.state.disableAddToBasket;
    if (Obj.quantity > 0) {
      temp = temp.concat([Obj]);
      disableAddToBasket[Obj.item_id] = true;
    }

    let total = this.getBasketTotal(temp);
    this.setState({ basket: temp, total: total, disableAddToBasket });
  }

  removePaypalBtnAfterReturn = () => {
    this.setState({ removePaypalButton: true });
  }

  removeFromBasket(e) {
    let temp = [];
    if (this.state.basket.length > 1) {
      temp = this.state.basket.filter(
        (item, i) => i !== parseInt(e.target.id, 10)
      );
    }
    let disableAddToBasket = this.state.disableAddToBasket;
    disableAddToBasket[e.target.id] = false;
    let total = this.getBasketTotal(temp);
    if (!total) {
      this.setState({
        checkout: false,
        basket: temp,
        total,
        disableAddToBasket
      });
    } else {
      this.setState({ basket: temp, total }, () => {
        document.getElementById('paypal-button').innerHTML = '';
        pp.render(total, null);
      });
    }
  }

  getBasketTotal(arr) {
    let total = 0;
    arr.forEach((element) => {
      total += element.total_amount;
    });
    return total;
  }

  getCategoryView(phase) {
    this.setState({ loading: true, current_phase: phase });
    let itemFilter = localStorage.getItem('itemFilter');
    setTimeout(() => {
      this.setState({ categories: phase.Categories, filter: itemFilter ? itemFilter : 'all', loading: false });
    }, 1000);
  }

  emptyBasket = () => {
    this.setState({ basket: [], total: 0, disableAddToBasket: {} });
  }

  hoverEffect() {
    let basket = document.getElementById('church_basket');
    let basketIcon = document.getElementById('basketIcon');

    basket.addEventListener('mouseout', function () {
      basketIcon.className = 'fa fa-box';
    });
    basket.addEventListener('mouseover', function () {
      basketIcon.className = 'fa fa-box-open';
    });
  }

  onSetFilter = (e) => {
    e.preventDefault();
    let filter;
    let { name } = e.target;
    localStorage.setItem('itemFilter', name);
    switch(name) {
    case 'all':
      filter = '';
      break;
    case 'balance':
      filter = [['items.payment_percentage.lt', '1']];
      break;
    case 'paid':
      filter = [['items.payment_percentage.gte', '1']];
      break;
    default:
      filter = '';
      break;
    }
    this.setState({
      filter: name,
      data: [],
      categories: [],
      loading: true
    });
    API.getAll(true, null, filter).then(res => {
      let phase = res.data.find(({id}) => id === this.state.current_phase.id);
      setTimeout(() => {
        this.setState({
          data: res.data,
          current_phase: phase,
          categories: phase.Categories,
          loading: false
        });
      }, 1000);
    }).catch(() => {});
  }

  render() {
    let { current_phase, categories } = this.state;
    return (
      <div className="container-fluid ">
        <Header />
        <WishListNav />
        <div className="row  ">
          <div className="main wishlist_body">
            {this.state.checkout ? (
              <Checkout
                handleReturn={this.returnFromCheckOut}
                basket={this.state.basket}
                total={this.state.total}
                remove={this.removeFromBasket.bind(this)}
                data={this.state.data.map(p => p.Categories).flat()}
                handleInput={this.handleInput}
                item={this.state.item}
                removePaypalBtnAfterReturn={this.removePaypalBtnAfterReturn}
                removePaypalButton={this.state.removePaypalButton}
              />
            ) : (
              <div>
                <Basket
                  Basket={this.state.basket}
                  data={this.state.data.map(p => p.Categories).flat()}
                  remove={this.removeFromBasket}
                  total={this.state.total}
                  cout={this.checkOut}
                  emptyBasket={this.emptyBasket}
                />
                <InnerNav
                  getCategoryView={this.getCategoryView.bind(this)}
                  current_phase={this.state.current_phase}
                  phases={this.state.data}
                />
                <ItemFilter
                  onSetFilter={this.onSetFilter}
                  filter={this.state.filter}
                />
                {this.state.loading ? <Loader /> :
                  current_phase && categories.length ? categories.map((cat, i) => {
                    return (
                      <Category
                        category={cat}
                        key={`${current_phase.phase_name}-${cat.id}`}
                        indicator={i}
                        handleAddToBasket={this.addToBasket}
                        basket={this.state.basket}
                        remove={this.removeFromBasket}
                        total={this.state.total}
                        disableAddToBasket={this.state.disableAddToBasket}
                      />
                    );
                  }) : <NoData />}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
