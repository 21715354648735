import React from 'react';
import DescriptionInput from '../DashBoardComponents/DescriptionInput';
import TableInput from '../DashBoardComponents/TableInput';

const Table = (props)=>{
  return props.categories.map((cat, i) => {
    return (
      <div className="table-responsive" key={`cat-${i}`}>
        <table className="table table-striped table-bordered table-dark">
          <thead>
            <tr className="table-title bg-info">
              <th className=" text-center ">{cat.category_name.toUpperCase()}<button className="btn btn-warning btn-block text-muted"  onClick={props.Delete}  name="category" type="submit" id={cat.id}>DELETE</button></th>
            </tr>
            <tr>
              <th scope="col">#ID</th>
              <th scope="col">ITEM</th>
              <th scope="col">ITEM DESCRIPTION</th>
              <th scope="col">PRICE/ITEM</th>
              <th scope="col">REQUESTED</th>
              <th scope="col">TOTAL REQUESTED</th>
              <th scope="col">% COLLECTED</th>
              <th scope="col">VALUE COLLECTED</th>
              <th scope="col">URL</th>
              <th scope="col">MORE</th>
            </tr>
          </thead>
          <tbody>
            {cat.Items.map((item,i)=>{
              return (
                <tr className={item.payment_percentage <= 0.8 ? 'bg_black': 'bg-success'} key={`item-${i}`}>
                  <th scope="row">{item.id}</th>
                  {props.inputCell === `${item.id}-name` ? <td><TableInput inputType='text' itemID={item.id} fieldValue={item.item_name} field="item_name" handleItemUpdate={props.handleInputUpdate}/></td> : <td id={`${item.id}-name`} onDoubleClick={props.handleDoubleClick}>{item.item_name}</td>}
                  {props.inputCell === `${item.id}-description` ? <td><DescriptionInput itemID={item.id} field="item_description" fieldValue={item.item_description} handleItemUpdate={props.handleInputUpdate}/></td> : <td id={`${item.id}-description`} onDoubleClick={props.handleDoubleClick}>{item.item_description}</td>}
                  {props.inputCell === `${item.id}-price` ? <td><TableInput inputType='number' itemID={item.id} field="item_price"  fieldValue={item.item_price} handleItemUpdate={props.handleInputUpdate}/></td> : <td id={`${item.id}-price`} onDoubleClick={props.handleDoubleClick}>$ {item.item_price}</td>}
                  {props.inputCell === `${item.id}-quantity` ? <td><TableInput inputType='number' itemID={item.id} field="item_requested" fieldValue={item.item_requested} handleItemUpdate={props.handleInputUpdate} /></td> : <td id={`${item.id}-quantity`} onDoubleClick={props.handleDoubleClick}>{item.item_requested}</td>}
                  <td id={`${item.id}-total`}>$ {item.totalRequested.toFixed(2)}</td>
                  <td id={`${item.id}-percent`}>{(item.payment_percentage*100).toFixed(2)}</td>
                  {props.inputCell === `${item.id}-fulfilled` ? <td><TableInput inputType='number' itemID={item.id} field="totalFulfilled" fieldValue={item.totalFulfilled} handleItemUpdate={props.handleInputUpdate} /></td> : <td id={`${item.id}-fulfilled`} onDoubleClick={props.handleDoubleClick}>$ {item.totalFulfilled}</td>}
                  {props.inputCell === `${item.id}-itemUrl` ? <td><TableInput inputType="text" fieldValue={item.item_Url} itemID={item.id} field="item_Url" handleItemUpdate={props.handleInputUpdate}/></td> : <td id={`${item.id}-itemUrl`} onDoubleClick={props.handleDoubleClick}>{`${item.item_Url}`}</td>}
                  <td>

                    <select className="input-group" onChange={(e)=>{props.handleCategoryChange(e,item.id);}}>
                      <option>assign new category...</option>
                      {props.categories.map((cat,i)=>{
                        return (<option value={cat.id} key={i}>
                          {cat.category_name}
                        </option>);
                      })}
                    </select>
                    <input type="file" id="upload-image" onChange={props.handleFileChange} placeholder="upload new image"/>
                    <button className="btn btn-info  btn-block text-white" onClick={()=>{props.imageUpload(item.id);}}>Upload</button>
                    <button className="btn btn-warning btn-block text-muted"
                      onClick={props.Delete} type="submit"
                      id={item.id}>DELETE ITEM</button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  });
};

export default Table;