
import React from 'react';


const PhaseForm = function(props) {
  return (
    <div className="formContainer">
      <div className="form-group row">
        <label className="col-form-label col-2" htmlFor="phase_name_submit">Phase Name</label>
        <input
          className="form-control-sm col-6"
          name="phase_name"
          onChange={props.handleInputChange}
          type="text"
          id="phase_name_submit"
          placeholder="Type New Phase Here..."
        />
      </div>
      <div className="form-group row">
        <label className="col-form-label col-2" htmlFor="phase_active_submit">Phase Active</label>
        <div className="row col-6 active-radios">
          <input
            className="form-control-sm col-1"
            name="phase_active"
            onChange={props.handleInputChange}
            type="radio"
            value={1}
            id="phase_active_submit_yes"
          />
          <label className="col-form-label col-1" htmlFor="phase_active_submit_yes">Yes</label>
          <input
            name="phase_active"
            onChange={props.handleInputChange}
            type="radio"
            value={0}
            className="form-control-sm col-1"
            id="phase_active_submit_no"
          />
          <label className="col-form-label col-1" htmlFor="phase_active_submit_no">No</label>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-form-label col-2" htmlFor="phase_position_submit">Phase Number</label>
        <input
          name="phase_position"
          onChange={props.handleInputChange}
          type="number"
          className="form-control-sm col-6"
          id="phase_position_submit"
          placeholder="1"
          min="1"
        />
      </div>
      {props.alert ? <span className={`alert-text form-text ${props.alert === 'Successfully Created' ? 'text-success':'text-danger'}`}>{props.alert} <i className="dismiss-alert fas fa-times-circle" onClick={props.clearAlert}></i></span> : null }
      <button
        type="button"
        className="btn btn-primary"
        onClick={props.handleAdd}>
        Submit
      </button>
    </div>
  );
};

export default PhaseForm;