import React from 'react';

const CheckoutTable = (props)=>{
  return (
    <tr>
      <td className="text-center">{props.name}</td>
      <td className="text-center">{(props.quantity * 100).toFixed(2)} %</td>
      <td className="text-center">$ {props.total}</td>
      <td className="text-center"><button className="btn-block bg-danger" id={props.arrayIndex} onClick={props.removeItem}>delete</button></td>
    </tr>

  );
};

export default CheckoutTable;