import React from 'react';
import './NoData.css';

export const NoData = (props) => {
  return (
    <div className="no-data-container">
      <span className="center-icon text-white primary-color"><i className="fas fa-search"></i></span>
      <h5>Filtered Results Empty</h5>
      <p>{props.message}</p>
    </div>
  );
};