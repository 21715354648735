import React from 'react';

const ItemFilter = (props) => {

  return (
    <div className="main-menu col-sm-12 col-md-12">
      <ul className="menu-items">
        <li className={`action ${props.filter === 'all' ? 'active' : ''}`}>
          <button
            className="btn btn-block"
            name="all"
            onClick={props.onSetFilter}
            type="submit">
            Show All
          </button>
        </li>
        <li
          className={`action ${
            props.filter === 'balance' ? 'active' : ''
          }`}>
          <button
            className="btn btn-block"
            name="balance"
            onClick={props.onSetFilter}
            type="submit">
            Show Items With Balance
          </button>
        </li>
        <li
          className={`action ${
            props.filter === 'paid' ? 'active' : ''
          }`}>
          <button
            className="btn btn-block"
            name="paid"
            onClick={props.onSetFilter}
            type="submit">
            Show Paid
          </button>
        </li>
      </ul>
    </div>
  );
};

export default ItemFilter;