
import React from 'react';


const ItemDonateBtn = (props)=>{
  const payment_percentage = ((props.item.payment_percentage * 100) > 100);
  let disabled = props.invalidValue || payment_percentage || props.disableAddToBasket;

  return (
    <div className={props.col}>
      <button className="btn btn-block addtoBasket text-white primary-color" type="submit"
        onClick={()=>{props.Item_Submit({item_name:props.item.item_name,
          item_id: props.item.id,
          quantity: props.quantity,
          total_amount: props.donationValue
        });}} disabled={disabled}>Add to Basket</button>
    </div>
  );

};

export default ItemDonateBtn;