import React from 'react';
import './Footer.css';


const Footer = ()=>{
  const date = new Date().getFullYear();
  return (
    <div className="footerContainer primary-color text-white">
      <div className="footer col-md-12 col-sm-12 col-xs-12 ">
        <span className="copyright-container">
          <p align="center">
            Developed With
            <span id="heart">&#10084;</span>
            <a href="https://www.linkedin.com/in/m-soliman/" target="_blank" rel="noreferrer noopener"><i>&lt; Mark S /&gt;</i></a>
          </p>

        </span>
        <p>&copy; {date}</p>
      </div>
    </div>
  );

};

export default Footer;