import React, { Component } from 'react';
import { default as API } from '../../util/API';
import { default as USERAPI } from '../../util/USERAPI';
import { default as DashboardLogin } from '../../components/DashboardLogin';
import DashBoardMenu from '../../components/DashBoardMenu';
import './DashBoard.css';
import { Header } from '../../components/Header';
import { default as WishListNav } from '../../components/WishListNav';
import DashBoardSummary from '../../components/DashBoardSummary/DashBoardSummary';
import CategoryForm from '../../components/DashBoardComponents/CategoryForm';
import ItemForm from '../../components/DashBoardComponents/ItemForm';
import PhaseForm from '../../components/DashBoardComponents/PhaseForm';

export default class DashBoard extends Component {
  constructor() {
    super();
    this.state = {
      auth: false,
      file: '',
      email: '',
      password: '',
      data: [],
      alert: '',
      category_name: '',
      phase_name: '',
      phase_active: '',
      phase_postion: '',
      item_obj: {},
      dashBoardComp: 'summary',
      cellId: '',
      current_phase: null,
      showPhaseUpdateMessage: false
    };
  }

  componentDidMount() {
    let auth = localStorage.getItem('auth');
    if (auth === '1') {
      API.getAll(null, 'item.id')
        .then((res) => {
          API.getItemAmounts().then(res => console.log(res));
          this.setState({ data: res.data, auth: true, current_phase: this.state.current_phase ? this.state.current_phase : res.data[0] });
        });
    } else {
      this.setState({ auth: auth === '1', current_phase: this.state.data.length ? this.state.data[0] : null });
    }



  }

  componentDidUpdate() {
    if (this.state.auth && this.state.data.length === 0) {
      API.getAll(null, 'item.id')
        .then((res) => {
          this.setState({ data: res.data, auth: true, current_phase: this.state.current_phase ? this.state.current_phase : res.data[0] });
        });
    }
  }

  login = (event) => {
    event.preventDefault();

    const username = this.state.email;
    const password = this.state.password;
    USERAPI.login(username, password)
      .then((res) => {
        if (res.data.msg) {
          API.getAll(null, 'item.id').then(result => {
            localStorage.setItem('auth', 1);
            this.setState({ auth: true, data: result.data, current_phase: result.data[0] });
          });
        }
      })
      .catch(() => {
        localStorage.removeItem('auth');
        this.setState({ auth: false });
        alert('User Not Found contact admin!');
      });
  }

  logout = () => {
    USERAPI.logout()
      .then(() => {
        localStorage.clear();
        this.setState({ auth: false });
      })
      .catch(() => {
        alert('something went wrong reload page');
      });
  }

  setView = (e) => {
    this.setState({ dashBoardComp: e.target.name, alert: '' });
  }

  setCategoryView = (phase) => {
    this.setState({ current_phase: phase });
  }

  handleInputChange = (event) => {
    let target = event.target.name;
    let input = event.target.value.trim();
    this.setState({
      [target]: input,
    });
  }

  clearItemImageInput = (event) => {
    let { item_obj } = this.state;
    item_obj[event.target.name] = null;
    let itemUploadInput = document.getElementById('item_image_submit');
    itemUploadInput.value = '';
    this.setState({
      item_obj,
      alert: '',
    });
  }

  phaseActiveUpdate = (e) => {
    API.updatePhase({active: e.target.value}, this.state.current_phase.id)
      .then(() => {
        API.getAll(null, 'item.id').then((res) => {
          this.setState({showPhaseUpdateMessage: true, data: res.data, current_phase: res.data.filter((phase) => phase.id === this.state.current_phase.id)[0]});
        });
      }).catch(() => {
        localStorage.clear();
        this.setState({ auth: false });
      });
  }
  resetAlert = () => {
    this.setState({ alert: ''});
  }
  phaseAdd = (event) => {
    event.preventDefault();
    let phases = this.state.data.map((phase) => {
      return phase.phase_name.toLowerCase();
    });

    if (phases.indexOf(this.state.phase_name.toLowerCase()) > -1) {
      this.setState({ alert: 'Phase Already Exists' });
    } else {
      API.addPhase({
        phase_name: this.state.phase_name,
        active: this.state.phase_active,
        position: this.state.phase_position
      })
        .then(() => {
          API.getAll(null, 'item.id')
            .then((res) => {
              this.setState({ alert: 'Successfully Created', data: res.data });
              setTimeout(this.resetAlert, 1000);
            })
            .catch(() => {
              alert('please login again');
              localStorage.clear();
              this.setState({ auth: false });
            });
        })
        .catch(() => {
          alert('please login again');
          localStorage.clear();
          this.setState({ alert: 'Unable to Create', auth: false });
        });
    }
  }
  categoryAdd = (event) => {
    event.preventDefault();

    let categories = this.state.data.filter(({ id }) => id === Number(this.state.category_phase) )[0].Categories.map((cat) => {
      return cat.category_name.toLowerCase();
    });

    if (categories.indexOf(this.state.category_name.toLowerCase()) > -1) {
      this.setState({ alert: 'Category Already Exists' });
    } else if (this.state.category_name.search(/[^a-zA-Z][\s]/) > -1) {
      this.setState({ alert: 'category can only have letters' });
    } else {
      API.addCategory({
        category_name: this.state.category_name,
        PhaseId: this.state.category_phase,
      })
        .then(() => {
          API.getAll(null, 'item.id')
            .then((res) => {
              this.setState({ alert: 'Successfully Created', data: res.data });
            })
            .catch(() => {
              alert('please login again');
              localStorage.clear();
              this.setState({ auth: false });
            });
        })
        .catch(() => {
          alert('please login again');
          localStorage.clear();
          this.setState({ alert: 'Unable to Create', auth: false });
        });
    }
  }

  deleteItem = (e) => {
    let id = e.target.id;
    if (e.target.name === 'category') {
      API.deleteCategory(e.target.id).then(() => {
        API.getAll(null, 'item.id')
          .then((res) => {
            this.setState({ data: res.data });
          });
      }).catch(() => {
        alert('please login again');
        localStorage.clear();
        this.setState({ auth: false });
      });
    } else {
      API.deleteItem(id).then(() => {
        API.getAll(null, 'item.id')
          .then((res) => {
            this.setState({ data: res.data });
          });

      }).catch((e) => {
        console.log('error',e);
        alert('please login again');
        localStorage.clear();
        this.setState({ auth: false });
      });
    }
  }

  turnIntoInput = (e) => {
    this.setState({ cellId: e.target.id });
  }


  handleItemUpdate = (e, id, field) => {
    if (e.key === 'Enter') {
      API.updateItemField(id, e.target.value, field)
        .then(() => {
          API.getAll(null, 'item.id').then((res) => {
            this.setState({ data: res.data, cellId: '' });
          });
        })
        .catch((e) => {
          if (e.response.status === 401) {
            alert('please login again');
            localStorage.clear();
            this.setState({ auth: false });
          }
        });
    }
  }

  onFileInputChange = (e) => {
    const file = e.target.files[0];

    this.setState({ file: file });
  }

  handleImageUpdate = (id) => {
    API.updateImage(id, { item_image: this.state.file })
      .then((res) => {
        if (res) {
          API.getAll(null, 'item.id').then((res) => {
            alert('image updated');
            this.setState({ data: res.data });
          });
        }
      })
      .catch((e) => {
        if (e.response.status === 422) {
          alert(e.response.data.message);
        } else if (e.response.status === 401) {
          alert('please login again');
          localStorage.clear();
          this.setState({ auth: false });
        }
      });
  }

  handleItemForm = (e) => {
    let dummy = this.state.item_obj;
    dummy[e.target.name] = e.target.value;
    this.setState({ item_obj: dummy });
  }

  handleItemSubmit = (e) => {
    e.preventDefault();

    if (Object.keys(this.state.item_obj).length < 5) {
      this.setState({ alert: 'please fill missing fields' });
    } else if (
      this.state.item_obj.item_name.search(/[^a-zA-Z][\s][^0-9][^,/-_']/g) > -1
    ) {
      this.setState({
        alert: 'item contain only English letters, spaces and numbers\n',
      });
    } else {
      let form = document.querySelector('form');

      let data = new FormData(form);

      API.addItem(data)
        .then(() => {
          API.getAll(null, 'item.id').then((newRes) => {
            this.setState({ alert: 'success', data: newRes.data });
          });
        })
        .catch((e) => {
          if(e.response.status === 401) {
            alert('please login again');
            localStorage.clear();
            this.setState({ alert: 'Unable to Create', auth: false });
          } else if (e.response.status === 422) {
            this.setState({ alert: e.response.data.message });
          }
        });
    }
  }

  handleCategoryAssign = (e, item_id) => {
    e.preventDefault();

    let catId = e.target.value;
    let itemId = item_id;

    API.updateCategory(catId, itemId)
      .then(() => {
        API.getAll(null, 'item.id').then(res => {
          this.setState({data: res.data});
        });
      })
      .catch((err) => {
        console.log(err);
        alert('unable to update');
      });
  }

  clearAlert = ()  => {
    this.setState({alert: ''});
  }

  currentComponent = () => {
    switch (this.state.dashBoardComp) {
    case 'summary':
      return (
        <DashBoardSummary
          setView={this.setView}
          dashBoardComp={this.state.dashBoardComp}
          logout={this.logout}
          handlePhaseView={this.setCategoryView.bind(this)}
          current_phase={this.state.current_phase}
          handleCategoryAssign={this.handleCategoryAssign.bind(this)}
          handleFileChange={this.onFileInputChange}
          imageUpload={this.handleImageUpdate}
          data={this.state.data}
          Delete={this.deleteItem}
          handleDoubleClick={this.turnIntoInput}
          inputCell={this.state.cellId}
          handleInputUpdate={this.handleItemUpdate}
          showPhaseUpdateMessage={this.state.showPhaseUpdateMessage}
          updatePhaseActive={this.phaseActiveUpdate}
        />
      );
    case 'addPhase':
      return (<PhaseForm
        alert={this.state.alert}
        clearAlert={this.clearAlert}
        handleInputChange={this.handleInputChange}
        handleAdd={this.phaseAdd}
      />);
    case 'addItem':
      return (
        <ItemForm
          data={this.state.data}
          clearItemImageInput={this.clearItemImageInput}
          alert={this.state.alert}
          clearAlert={this.clearAlert}
          handleInputChange={this.handleItemForm}
          handleSubmit={this.handleItemSubmit}
        />
      );
    case 'addCategory':
      return (
        <CategoryForm
          phases={this.state.data}
          alert={this.state.alert}
          clearAlert={this.clearAlert}
          handleInputChange={this.handleInputChange}
          handleAdd={this.categoryAdd}
        />
      );
    default:
      return (
        <DashBoardSummary
          setView={this.setView}
          dashBoardComp={this.state.dashBoardComp}
          logout={this.logout}
          data={this.state.data}
          handlePhaseView={this.setCategoryView.bind(this)}
          current_phase={this.state.current_phase}
          handleCategoryAssign={this.handleCategoryAssign.bind(this)}
          handleFileChange={this.onFileInputChange}
          imageUpload={this.handleImageUpdate}
          Delete={this.deleteItem}
          handleDoubleClick={this.turnIntoInput}
          inputCell={this.state.cellId}
          handleInputUpdate={this.handleItemUpdate}
        />
      );
    }
  }

  render() {
    return (
      <div className="container-fluid">
        <Header />
        <WishListNav />
        {!this.state.auth ? (
          <DashboardLogin
            handleLogin={this.login}
            handleInputChange={this.handleInputChange}
          />
        ) : (
          <div className="row">
            <DashBoardMenu
              setView={this.setView}
              current={this.state.dashBoardComp}
              handleLogout={this.logout}
            />
            <div className="main col-md-12">{this.currentComponent()}</div>
          </div>
        )}
      </div>
    );
  }
}
