import React from 'react';


export const PhaseActive = (props) => {

  return (
    <div className="form-group row phase-actions">
      <label className="col-form-label col-1" htmlFor="phase_active_submit">Phase Active</label>
      <div className="col-6 active-radios">
        <input
          className="form-control-sm col-1"
          name="phase_active"
          onChange={props.handleInputChange}
          type="radio"
          value={1}
          id="phase_active_submit_yes"
          checked={props.active ? 'checked' : false}
        />
        <label className="col-form-label col-1" htmlFor="phase_active_submit_yes">Yes</label>
        <input
          name="phase_active"
          onChange={props.handleInputChange}
          type="radio"
          value={0}
          className="form-control-sm col-1"
          id="phase_active_submit_no"
          checked={props.active ? false : 'checked'}
        />
        <label className="col-form-label col-1" htmlFor="phase_active_submit_no">No</label>
        <div className="col-2" style={{display: 'flex', margin: '5px 0',alignItems: 'center'}}>
          <p style={{fontSize: '0.8rem'}}>Toggle yes/no to update active state.</p>
        </div>
      </div>

    </div>
  );
};