import React from 'react';
import './Home.css';
import { NavLink } from 'react-router-dom';
import {Header} from '../../components/Header';
import {default as WishListNav} from '../../components/WishListNav';

const Home = ()=>{

  return (
    <div className="container-fluid">
      <Header />
      <WishListNav />
      <div className="row justify-content-center">
        <div className="col-sm-12 justify-content-center QouteContainer primary-text">
          <i><h6 className="card-title bibleQoute">&ldquo; The God of heaven Himself will prosper us;  therefore we His servants will arise and build &rdquo;</h6></i>
          <span>(Nehemiah 2:20)</span>
        </div>
      </div>
      <div className="row main justify-content-center">
        <div className="card col-sm-12  primary-color">
          <div className="card-img imgContainer" />
          <div className="card-img-overlay">
            <i><h2 className="right-box primary-color transparent07">Building Up,<br /> Reaching Out</h2></i>
            <div className="overlayContent">
              <NavLink className="nav-link secondary-color"  to="/wishlist">Go To Wishlist</NavLink>
            </div>
          </div>
          <div className="card-body">
            <h3 className="card-title">Welcome to Archangel Micheal&apos;s</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;