import  React from 'react';

const TableInput = (props)=>{

  const { inputType, handleItemUpdate, itemID, field, fieldValue } = props;

  return (
    <input  type={inputType} onKeyUp={(e)=>{handleItemUpdate(e,itemID,field);}} defaultValue={fieldValue} autoFocus/>
  );

};

export default TableInput;