import React, { Component } from 'react';
import './WishListNav.css';
import { NavLink } from 'react-router-dom';

export default class WishListNav extends Component {
  constructor() {
    super();
    this.state = {
      wishlist: '',
      categories: [],
      modalShow: false,
    };
  }

  showModal() {
    this.state({ modalShow: true });
  }

  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-light justify-content-left position-sticky">
        <div className="layer primary-color" />
        <button
          className="navbar-toggler secondary-color"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <span className="nav-icon text-white"><i className="fas fa-bars"></i></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <div className="row justify-content-start">
            <ul className="nav  col">
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  style={{ color: 'white' }}
                  exact
                  to="/">
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link "
                  style={{ color: 'white' }}
                  to="/wishlist">
                  Church Wishlist
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}
