
import React from 'react';

const ItemDetail = (props) => {

  let description = formatDescription(props.item.item_description);
  return (
    <div className={props.col}>
      <div className="row item-box-content item-detail">
        <span className="title">{props.item.item_name.toUpperCase()}</span>
        {Array.isArray(description) ?
          <React.Fragment>{description.map((piece, index) => {
            return (
              <React.Fragment key={index}>
                <p className="text-dark no-margin">{piece}</p>
              </React.Fragment>
            );
          })}</React.Fragment> : <p className="text-dark description">
            {description}
          </p>

        }

        {props.item.totalRequested > 10000 ? (
          <p className="text-dark" style={{ fontSize: '11px' }}>
            * max paypal transaction is $10,000, please enter donation value upto $10,000
            or {' '}
            <a href="mailto:donation@amcsv.org">contact us</a>{' '}
          </p>
        ) : null}
      </div>
    </div>
  );
};

function formatDescription(description) {
  if (description.match(/\\n/)) {
    description = description.replace(/\\n/g, ',');
    description = description.split(',');
  } else if(description.includes('\n')) {
    description = description.replace('\n', ',');
    description = description.split(',');
  }

  return description;
}

export default ItemDetail;
