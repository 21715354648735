
import React from 'react';

const ItemContribute = (props) => {
  return (
    <div className={props.col}>
      <span className="title" data-effect="solid" data-tip="Price of one item">Price / Item <i className="far fa-question-circle"></i></span>
      <h5
        style={{
          fontWeight: 600,
          color: '#911a1e',
        }}>{`$${props.item.item_price
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}</h5>
    </div>
  );
};

export default ItemContribute;
