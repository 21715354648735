
import React from 'react';
import './DashBoardSummary.css';
import Table from './Table';
import { InnerNav } from '../InnerNav';
import { PhaseActive } from '../DashBoardComponents/PhaseActive';
const DashBoardSummary = (props) => {

  let {
    data,
    current_phase,
    handleCategoryAssign,
    handlePhaseView,
    handleDoubleClick,
    handleFileChange,
    handleInputUpdate,
    inputCell,
    imageUpload,
    Delete,
    showPhaseUpdateMessage,
    updatePhaseActive
  } = props;

  return (
    <div>
      <InnerNav
        getCategoryView={handlePhaseView}
        current_phase={current_phase}
        phases={data}
      />
      <PhaseActive
        showMessage={showPhaseUpdateMessage}
        handleInputChange={updatePhaseActive}
        active={current_phase.active}
      />
      {data.map((phase, i) => {
        if (phase.id === current_phase.id) {
          return (
            <Table
              categories={phase.Categories}
              handleCategoryChange={handleCategoryAssign}
              key={`phase-${i}`}
              Delete={Delete}
              handleDoubleClick={handleDoubleClick}
              inputCell={inputCell}
              imageUpload={imageUpload}
              handleFileChange={handleFileChange}
              handleInputUpdate={handleInputUpdate}
            />
          );
        }
        return null;
      })}
    </div>
  );
};

export default DashBoardSummary;
