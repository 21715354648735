import React from 'react';
import './Header.css';

export const Header = ()=>{
  return(
    <div className="row">
      <div className="header col-md-12 col-sm-12 col-xs-12">
      </div>
    </div>
  );
};