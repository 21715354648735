
import React, { useState } from 'react';
import './forms.css';


const ItemForm = (props)=>{

  let [phase, setPhase] = useState({});

  return (
    <form>
      <div className="formContainer item_form">
        <div className="form-group row">
          <label className="col-form-label col-2" htmlFor="item_name_submit">Item Name</label>
          <input type="text" className="form-control-sm col-6" id="item_name_submit" placeholder="Type Item Name Here..." name="item_name" onChange={props.handleInputChange} />
        </div>
        <div className="form-group row">
          <label className="col-form-label col-2" htmlFor="item_description_submit">Item Description</label>
          <input type="text" className="form-control-sm col-6" id="item_description_submit" placeholder="Type Item Description Here..." name="item_description" onChange={props.handleInputChange} />
        </div>
        <div className="form-group row">
          <label className="col-form-label col-2" htmlFor="item_category_submit">Phase</label>
          <select className="custom-select form-control-sm col-6" name="PhaseId" onChange={(e) => setPhase(props.data.find((p) => p.id === Number(e.target.value) ))}>
            <option value="">pick a phase...</option>
            {props.data.map((phase,i)=>{
              return <option value={phase.id} key={i}>{phase.phase_name}</option>;
            })}
          </select>
        </div>

        {phase.id ? (<div className="form-group row">
          <label className="col-form-label col-2" htmlFor="item_category_submit">Category</label>
          <select className="custom-select form-control-sm col-6" name="CategoryId" onChange={props.handleInputChange}>
            <option value="">pick a category...</option>
            {props.data.find((p)=> p.id === phase.id).Categories.map((cat, i) => {
              return <option value={cat.id} key={i}>{cat.category_name}</option>;
            })}
          </select>
        </div>) : null}

        <div className="form-group row">
          <label className="col-form-label col-2" htmlFor="item_price_submit">Item Price</label>
          <input type="number" className="form-control-sm col-6" id="item_price_submit" placeholder="Enter Price..." name="item_price" onChange={props.handleInputChange} />
        </div>
        <div className="form-group row">
          <label className="col-form-label col-2" htmlFor="item_requested_submit">Item Total Count</label>
          <input type="number" className="form-control-sm col-6" id="item_requested_submit" placeholder="Enter Quantity..." name="item_requested" onChange={props.handleInputChange} />
        </div>
        <div className="form-group row">
          <label className="col-form-label col-2" htmlFor="item_link">Link</label>
          <input type="text" className="form-control-sm col-6" id="item_link" placeholder="http://..." name="item_Url" onChange={props.handleInputChange} />
        </div>
        <div className="form-group row">
          <label className="col-form-label col-2" htmlFor="item_image_submit">Item Image</label><br/>
          <input className="form-control-sm col-6" type="file" id="item_image_submit" placeholder="choose file..." name="item_image" onChange={props.handleInputChange}/>

          <span className="form-text text-muted">Only (JPEG, JPG, PNG) MAX SIZE 1MB</span>
        </div>
        {props.alert ? <span className={`alert-text form-text ${props.alert === 'success' ? 'text-success':'text-danger'}`}>{props.alert} <i className="dismiss-alert fas fa-times-circle" onClick={props.clearAlert}></i></span> : null }
        <button type="button" className="btn btn-block btn-primary" onClick={props.handleSubmit}>Submit</button>
        <button type="button" className="btn btn-block btn-dark" name="item_image" onClick={props.clearItemImageInput}>Clear Upload</button>
      </div>
    </form>
  );
};

export default ItemForm;