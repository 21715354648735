import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Footer from './components/Footer';
import { WishList } from './pages';
import { DashBoard, Home } from './pages';

class App extends Component {
  render() {
    return (
      <div className="App">
        <Router>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/wishlist" component={WishList} />
            <Route exact path="/admin" component={DashBoard} />
          </Switch>
        </Router>
        <Footer />
      </div>
    );
  }
}

export default App;
