import React, {Component} from 'react';
import {default as ppButton} from '../../util/Paypal';
import './Checkout.css';
import CheckoutTable from './CheckoutTable';

export default class Checkout extends Component {

  componentDidMount(){
    if(this.props.total !== 0){
      ppButton.render(this.props.total, this.props.basket);
    }
  }

  render(){
    return (
      <div className="row">
        <div className="col-md-10 col-sm-10 mainCheckOut">

          {this.props.basket.length ? (
            <table className="table checkouttable">
              <thead>
                <tr>
                  <th scope="col" className="text-center">ITEM</th>
                  <th scope="col" className="text-center">Percent Contribution</th>
                  <th scope="col" className="text-center">TOTAL</th>
                  <th scope="col" className="text-center"></th>
                </tr>
              </thead>
              <tbody>

                {this.props.basket.map((item,i)=>{
                  return (
                    <CheckoutTable item={item} name={item.item_name} removeItem={this.props.remove} total={item.total_amount} quantity={item.quantity} key={i} arrayIndex={i}/>
                  );
                })}
                <tr className="text-center"><td colSpan="2"></td><td ><h6 className="text-muted" style={{'borderTop':'1px solid #484848',margin:0}}>Subtotal: $ {this.props.total}</h6></td></tr>
              </tbody>
            </table>

          ): null}
          <div>
            <div className="row">
              <div className="col-md-4 col-sm-4 secureMSG bg-light">
                {this.removePaypalButton ? <h5>Thank you..updating item with your contribution..please hold</h5> : <div id="paypal-button" />}
                <div className="secured-message"></div>
                <button className="btn btn-block bg-danger" onClick={this.props.handleReturn}>Back to Wishlist</button>
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

