
import React from 'react';
import { NavLink} from 'react-router-dom';

import './Basket.css';
const Basket = (props) => {
  return (
    <div>
      <ul className="nav basket primary-color">
        {props.Basket.length ? <span className='number_of_items'>{props.Basket.length}</span>:null}
        <li className="nav-item primary-color" id="church_basket">
          <NavLink className="nav-link secondary-text" to="#"><i className="fa fa-box" id="basketIcon" /><br/><h6>Basket</h6></NavLink>
          <div className="basketCart card ">
            <div className="card-body primary-color">
              <ul className="card-text list-group-item">
                {props.total === 0 ? <li>Your Basket is Empty</li>:
                  <React.Fragment>
                    <li className="nav-item">
                      <button className="btn btn-dark" type="button" onClick={props.emptyBasket}>Empty Basket</button>
                    </li>
                    <li className="nav-item">
                      <button className="btn btn-success" type="button" id="checkout_btn" onClick={props.cout}>Proceed to Checkout</button>
                    </li>
                    <h6><b>Total</b>: ${props.total}</h6>
                  </React.Fragment>
                }
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );

};

export default Basket;