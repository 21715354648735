
import React from 'react';
import './InnerNav.css';

export const InnerNav = (props) => {
  let { current_phase, phases } = props;
  return (
    <div className="phase-nav">
      <nav className="navbar navbar-expand-lg navbar-light justify-content-left position-sticky">
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <div className="row justify-content-start">
            <ul className="nav innerNav col">
              {phases.map((p, index) => {
                return (
                  <li className="nav-item" key={index}>
                    <button
                      className={`btn btn-block phaseBtn primary-color nav-link ${
                        current_phase.id === p.id ? 'secondary-color' : ''
                      }`}
                      onClick={() => {
                        props.getCategoryView(p);
                      }}>
                      {p.phase_name}
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};
