import React, { useState } from 'react';

export default function DescriptionInput(props) {
  const { inputType, handleItemUpdate, itemID, field, fieldValue } = props;
  const [description, setDescription] = useState('');

  return (
    <React.Fragment>
      <textarea
        className="description-input"
        type={inputType}
        onChange={(e) => setDescription(e.target.value)}
        defaultValue={fieldValue} autoFocus
      />
      <button className="btn btn-dark" onClick={() => handleItemUpdate({key: 'Enter', target: {value: description}}, itemID, field)}>save</button>
    </React.Fragment>
  );
}
