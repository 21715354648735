import { default as API } from './API';

const paypal = window.paypal;

const FUNDING_SOURCES = [
  paypal.FUNDING.PAYPAL,
  paypal.FUNDING.VENMO,
  paypal.FUNDING.CARD,
  paypal.FUNDING.CREDIT,
  paypal.FUNDING.MYBANK
];

export default {
  render: function (total, items) {
    FUNDING_SOURCES.forEach((fundingSource)=> {
      let button = paypal.Buttons({
        fundingSource,
        createOrder:  function(data, actions) {
          return actions.order.create({
            'application_context' : {
              'shipping_preference':'NO_SHIPPING'
            },
            purchase_units: [
              {
                amount: {
                  value: total,
                  breakdown: {
                    item_total: {
                      value: total,
                      currency_code: 'USD'
                    }
                  }
                },
                // items: itemObj.data.map(({item_name, quantity, total_amount})=> {
                //   return {
                //     name: item_name,
                //     quantity: Number(quantity),
                //     unit_amount: {
                //       value: total_amount / Number(quantity),
                //       currency_code: 'USD'
                //     }
                //   };
                // })
              }
            ],
            return_url: window.location.href,
            cancel_url: window.location.href
          });
        },
        onApprove: async function(data, actions) {
          try {
            let details = await actions.order.capture();
            if (['COMPLETED','APPROVED','CREATED','SAVED'].includes(details.status)) {
              // TODO: capture details and send to db (needs planning) but for now update item as usual
              let { phone, email_address, name } = details.payer;
              let phoneStr = '';
              if (phone) {
                phoneStr += phone.phone_number.national_number;
              }
              let data = {
                itemsToUpdate: items,
                contributor: {
                  email: email_address,
                  name: `${name.given_name} ${name.surname}`,
                  phone: phoneStr
                }
              };
              let result = await API.updateRecord(data);
              if(result.data) {
                alert('Thank you for your generous donation');
                window.location.href = '/wishlist';
              }
            }
          } catch(e) {
            console.log('order capture:', e);
            alert('somthing went wrong - please contact us');
          }
        }
      });

      if (button.isEligible()) {
        button.render('#paypal-button');
      }
    });
  }
};
