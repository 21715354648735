
import React from 'react';

const Image = function (name, src, index) {
  let imgSrc = src ? src : '/assets/images/image-not-found.png';
  return src ? (<div className="img-container"><img id={`itemImage${index}`} className="item-image" src={imgSrc} alt={name} />
    <p>*Images are for illustration purposes only, may not reflect real items.</p></div>) : <img id={`itemImage${index}`} className="item-image" src={imgSrc} alt={name} />;
};

const ItemImage = function(props) {
  const { item, col, itemIndex } = props;
  const { item_Url, item_name, item_imageUrl } = item;
  let img = Image(item_name, item_imageUrl, itemIndex);
  return (
    <div className={col}>
      <span />
      {item.item_Url ? (
        <a href={item_Url} target="_blank" rel="noopener noreferrer">img</a>
      ) : img
      }
    </div>
  );

};

export default ItemImage;