import React, { Component } from 'react';
import './Category.css';
import Item from '../Item/Item';

export default class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      location: props.indicator,
      id: props.category.id,
      name: props.category.category_name.toUpperCase(),
      items: props.category.Items,
      icon: 'fa fa-caret-down',
      invalidValue: {},
      selectedQuantity: {},
      itemValues: {}
    };
  }

  handleSelectedQuantityChange = (e, item) => {
    let somethingWrong = (e.target.value > parseInt(e.target.max, 10) || e.target.value < 0);
    if(somethingWrong) {
      let tmp = this.state.invalidValue;
      tmp[item.id] = true;
      this.setState({
        invalidValue: tmp,
      });
    } else {
      let { selectedQuantity, itemValues } = this.state;
      selectedQuantity[item.id] = Number(e.target.value) / item.totalRequested;
      itemValues[item.id] = Number(e.target.value);
      this.setState({ itemValues, selectedQuantity, invalidValue: {} });
    }
  };

  handleClick = () => {
    if (this.state.open === false) {
      this.setState({ open: true, icon: 'fa fa-caret-down' });
    } else {
      this.setState({ open: false, icon: 'fa fa-caret-right' });
    }
  };

  render() {
    return (
      <div className="row ">
        <div className="row categoryHeader">
          <div
            className='col-md-12 col-sm-12 mainCategory primary-color text-white'
            id={this.state.name}
            onClick={this.handleClick}
          >
            <span
              style={{
                float: 'left',
                margin: '0.5%',
                display: 'inline-block',
              }}>
              <i className={this.state.open ? 'fa fa-caret-down' : 'fa fa-caret-right'} />
            </span>
            <span
              style={{
                display: 'inline-block',
                margin: '0.5%',
              }}>{`${this.state.name.toUpperCase()}`}</span>
          </div>
        </div>
        {this.state.items.map((item, i, array) => {
          return (
            <Item
              key={item.id}
              visible={this.state.open}
              itemIndex={i}
              item={item}
              col="col-sm-2 col-md-2"
              invalidValue={this.state.invalidValue[item.id]}
              quantityChange={this.handleSelectedQuantityChange}
              quantity={this.state.selectedQuantity[item.id]}
              donationValue={this.state.itemValues[item.id]}
              Item_Submit={this.props.handleAddToBasket}
              last={i === array.length - 1 ? true : false}
              disableAddToBasket={this.props.disableAddToBasket[item.id]}
            />
          );
        })}
      </div>
    );
  }
}
